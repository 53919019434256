<template>
  <div class="flex flex-col p-4">
    <div class="font-semibold text-center">Ticket Form</div>
    <div class="text-center text-hgray8 text-sm">
      Complete the ticket data in each column provided
    </div>

    <div class="grid gap-4">
      <div class="form-control">
        <label class="label">
          <span class="label-text">Department</span>
          <div class="inline-flex whitespace-nowrap" v-if="v$.form.department_id.$error">
            <img :src="SvgWarning" class="mr-2" />
            <span
              class="text-red-500 italic"
              style="font-size: 14px; line-height: 1; font-weight: 300"
            >
              Please select the department first!
            </span>
          </div>
        </label>
        <treeselect
          ref="ticket-department"
          class="h-9"
          :multiple="false"
          :options="listDepartment"
          v-model="form.department_id"
        />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Note</span>
          <div class="inline-flex whitespace-nowrap" v-if="v$.form.description.$error">
            <img :src="SvgWarning" class="mr-2" />
            <span
              class="text-red-500 italic"
              style="font-size: 14px; line-height: 1; font-weight: 300"
            >
              Note is required!
            </span>
          </div>
        </label>
        <textarea
          class="textarea h-24 textarea-bordered placeholder"
          placeholder="Type note here..."
          v-model="form.description"
        ></textarea>
      </div>

      <div class="form-control">
        <label class="label">
          <span class="label-text">Contact person</span>
          <div class="inline-flex whitespace-nowrap" v-if="v$.form.staff_name.$error">
            <img :src="SvgWarning" class="mr-2" />
            <span
              class="text-red-500 italic"
              style="font-size: 14px; line-height: 1; font-weight: 300"
            >
              Please enter contact person first!
            </span>
          </div>
        </label>
        <input
          type="text"
          placeholder="Type contact person here..."
          v-model="form.staff_name"
          class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent"
        />
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Phone number</span>
          <div class="inline-flex whitespace-nowrap" v-if="v$.form.staff_phone_number.$error">
            <img :src="SvgWarning" class="mr-2" />
            <span
              class="text-red-500 italic"
              style="font-size: 14px; line-height: 1; font-weight: 300"
            >
              Please enter phone number first!
            </span>
          </div>
        </label>
        <input
          type="number"
          placeholder="Type phone number here..."
          v-model="form.staff_phone_number"
          class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent"
        />
      </div>

      <div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">Image (Opsional)</span>
          </label>

          <div
            class="file-upload-wrapper break-words"
            :data-text="form.image ? `${form.image.name.slice(0, 30)}...` : dataText"
          >
            <input
              name="file-upload-field"
              type="file"
              class="file-upload-field"
              @input="uploadFile"
            />
          </div>
        </div>

        <div class="mt-4">
          <img :src="form.img" style="max-height: 112px" v-if="form.img" />
          <img :src="ImagePreview" style="max-height: 112px" v-else />
        </div>

        <div
          style="width: 100%; height: 14px; border-bottom: 2px solid #363636"
          class="text-center mt-3"
        >
          <span class="bg-white text-sm font-bold" style="padding: 0 7px"> Priority </span>
        </div>

        <div class="flex space-x-2 justify-between overflow-x-auto mt-4">
          <div
            class="pt-2.5 relative cursor-pointer flex-auto"
            v-for="p in priorities"
            :key="p"
            :class="p == form.priority ? `act-btn-prio bg-h${form.priority}` : 'list-btn-prio'"
            @click="setPrio(p)"
          >
            <p
              class="font-bold text-sm text-center capitalize"
              :class="p == form.priority ? `text-htext${form.priority}` : 'text-black'"
            >
              {{ p }}
            </p>
          </div>
        </div>

        <div class="mt-8 mb-4 text-center w-full">
          <button
            class="btn btn-primary text-base h-12 px-10 mx-auto capitalize border-0 hover:bg-hblue"
            @click="submit"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import group357 from '@/assets/images/group357.png'
import ErrorTicket from '@/assets/images/error-ticket.png'
import ImagePreview from '@/assets/images/no-image-xs.png'

import SvgWarning from '@/assets/icons/icon-warning.svg'

import Treeselect from '@riophae/vue-treeselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'open-nontechnical',
  components: { Treeselect },
  data() {
    return {
      group357,
      ErrorTicket,
      ImagePreview,
      SvgWarning,

      priorities: ['low', 'medium', 'high'],
      priority: 'low',
      dataText: 'Select your file',
      form: {
        department_id: null,
        description: '',
        staff_name: '',
        staff_phone_number: '',
        priority: 'low',
        image: null,
        img: null
      }
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      form: {
        description: { required },
        department_id: { required },
        priority: { required },
        staff_name: { required },
        staff_phone_number: { required }
      }
    }
  },
  computed: {
    ...mapGetters('ticket', ['listDepartment'])
  },
  mounted() {
    this.GET_DEPARTMENTS()
  },
  methods: {
    ...mapActions('modal', ['MODAL_CONFIRM_NEW_TICKET']),
    ...mapActions('ticket', ['TICKET_FORM', 'GET_DEPARTMENTS']),
    setPrio(val) {
      this.form.priority = val
    },
    async submit() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }

      let form = this.form
      form.ticket_type_id = this.$route.params.id

      await this.TICKET_FORM(form)

      this.MODAL_CONFIRM_NEW_TICKET(true)
    },
    uploadFile(e) {
      this.form.image = e.target.files[0]
      this.form.img = URL.createObjectURL(e.target.files[0])
    }
  }
}
</script>
